export default {
    routes: {
        home: '/',
        launch: '/launch',
        login: '/login',
        scoringProjects: '/scoringprojects',
        scoringProject: '/scoringproject/:id',
        editScoringProject: '/scoringproject/:id/edit',
        createScoringProject: '/scoringprojects/create',
        scoringProjectLinkDelivery: '/scoringproject/:id/linkdelivery',
        scoringProjectDeliveryDetails: '/scoringproject/:projectId/delivery/:deliveryId',
        scoringProjectLinkingDeliveryDetails: '/scoringproject/:projectId/link-delivery/:deliveryId',
        enrollmentValidation: '/scoringproject/:id/enrollment-validation',
        monitoringDetails: '/monitoringdetails/:scoringProjectId/:userId',
        reassignTasks: '/reassign/:scoringProjectId/:userId/:deliveryId/:enrollmentId/:criterion',
        delivery: '/delivery/:id',
        deliveryDetails: '/deliverydetails/:deliveryId',
        deliveries: '/deliveries',
        task: '/task/:deliveryId/:taskId',
        users: '/users',
        ltiSubmitted: '/submitted',
        ltiError: '/ltiError',
        error: '/error'
    },
    endpoints: {
        login: '/api/v1/auth/token/create',
        refreshToken: 'api/v1/auth/token/refresh',
        exchangeToken: 'api/v1/auth/token/exchange',
        importEnrollments: '/api/v1/import/csv/enrollments',
        importUsers: '/api/v1/import/csv/users',
        enrollment: '/api/v1/enrollments/:id',
        scoringProjects: '/api/v1/scoring-projects',
        scoringProject: '/api/v1/scoring-projects/:id',
        scoringProjectDeliveries: '/api/v1/scoring-projects/:id/deliveries',
        scoringProjectEnrollments: '/api/v1/scoring-projects/:id/enrollments',
        scoringProjectLinkDelivery: '/api/v1/scoring-projects/:id/link-delivery',
        scoringProjectUnlinkDelivery: '/api/v1/scoring-projects/:id/unlink-delivery',
        scoringProjectMonitoring: '/api/v1/monitoring/scoring-projects/:id/assignees',
        monitoringDetails: '/api/v1/monitoring/scoring-projects/:scoringProjectId/assignees/:userId',
        eligibleScorers: '/api/v1/reassignment/enrollments/:enrollmentId/candidates',
        reassignTasks: '/api/v1/reassignment/reassign-tasks',
        deliveries: '/api/v1/deliveries',
        delivery: '/api/v1/deliveries/:id',
        submitDelivery: '/api/v1/deliveries/:id/tasks/submit',
        tasks: '/api/v1/tasks',
        task: '/api/v1/tasks/:id',
        complainScores: '/api/v1/scoring-violations',
        user: '/api/v1/users/:id',
        users: '/api/v1/users'
    }
};
