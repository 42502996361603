import config from '@/config';
import { log } from '@/core/utils';
import * as authService from '../../services/authService';
import { AuthError } from '@/errors';

/**
 * Guards determine whether a given path will be handled by the route controller or not
 * @callback Guard
 * @param {Function} next The callback that must be called to execute the route controller
 * @param {typeof import('../router.js').default} router
 * @returns {Promise}
 */

/**
 *
 * @param {Array<string>} allowedRoles
 * @returns {Guard}
 */

export const getRolesGuard = allowedRoles => {
    /**
     *
     * @type Guard
     */
    const roleGuard = (next, router) =>
        authService
            .getUser()
            .then(
                user => {
                    const isAllowed = user.roles.some(value => allowedRoles.includes(value));

                    if (isAllowed) {
                        return next();
                    }

                    const isLtiUser = user.roles.includes(authService.ROLE_LTI_USER);
                    if (isLtiUser) {
                        log.error('User is not allowed to access this page');
                        router.redirect(config.routes.ltiError);
                        return;
                    }

                    throw new AuthError('User is not allowed to access this page');
                },
                error => {
                    if (allowedRoles.includes(authService.ROLE_NOT_LOGGED_IN)) {
                        return next();
                    }
                    log.error(error);
                    router.redirect({
                        backPath: window.location.pathname + window.location.search,
                        url: config.routes.login
                    });
                    return;
                }
            )
            .catch(error => {
                log.error(error);
                router.redirect(config.routes.error);
            });

    return roleGuard;
};

/**
 *
 * @type Guard
 */
export const loginGuard = (next, router) =>
    authService.getUser().then(
        user => {
            const isLtiUser = user.roles.includes(authService.ROLE_LTI_USER);
            if (isLtiUser) {
                log.error('User is not allowed to access this page');
                router.redirect(config.routes.ltiError);
                return;
            }

            return next();
        },
        () => next()
    );
